import { Suspense, useEffect, useState } from "react";
import {
  ClockCircleOutlined,
  DashboardOutlined,
  FieldTimeOutlined,
  FileDoneOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Row, Spin } from "antd";
import { Link, Outlet, useLocation } from "react-router-dom";
import Header from "@/components/Header/index.jsx";
import FiniteTimeFavicon from "@/assets/new_finitetime_logo.svg";
import FiniteTimeLogo from "@/assets/new_finitetime_logo_box.svg";
import "./Home.css";
import Timer from "@/components/Timer/index.jsx";
import TodoButton from "@/components/TodoButton/index.jsx";
import insightServices from "@/services/apiServices/insightServices/index.js";

const { Content, Sider } = Layout;

function getItem(label, key, icon) {
  return {
    key,
    icon,
    label,
  };
}

const sidebarItems = [
  getItem("Timeline", "/timeline", <FieldTimeOutlined />),
  getItem("Dashboard", "/overview", <DashboardOutlined />),
  getItem("To-do", "/todo", <FileDoneOutlined />),
  getItem("Scheduling links", "/schedule-link", <LinkOutlined />),
  getItem("Time budget", "/time-budget", <ClockCircleOutlined />),
];

const Home = () => {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();

  const [pathname, setPathname] = useState(location?.pathname);

  const [finiteAppStatusLoading, setFiniteAppStatusLoading] = useState(true);
  const [isFiniteAppAlive, setIsFiniteAppAlive] = useState(false);
  const [lastAppActiveText, setLastAppActiveText] = useState("");

  useEffect(() => {
    setPathname(location?.pathname);
  }, [location?.pathname]);

  function timeAgo(givenTime) {
    const now = new Date();
    const diffInMilliseconds = now - new Date(givenTime);
    const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);

    if (diffInSeconds < 60) {
      return `${diffInSeconds} sec ago`;
    } else if (diffInMinutes < 60) {
      return `${diffInMinutes} min ago`;
    } else if (diffInHours < 24) {
      return `${diffInHours} hour${diffInHours > 1 ? "s" : ""} ago`;
    } else if (diffInDays <= 15) {
      return `${diffInDays} day${diffInDays > 1 ? "s" : ""} ago`;
    } else {
      // Return exact date for differences over 15 days
      return new Date(givenTime).toLocaleDateString();
    }
  }

  useEffect(() => {
    let timer;
    setFiniteAppStatusLoading(true);

    async function getDeviceAliveStatus() {
      try {
        const resp = await insightServices.getIsDeviceAlive();

        const deviceList = resp?.data?.devices;
        if (deviceList && deviceList.length > 0) {
          const firstDeviceItem = deviceList[0];
          const lastAliveAt = new Date(firstDeviceItem.alive_at);
          const dateNow = new Date();
          const diffInMilliseconds = dateNow.getTime() - lastAliveAt.getTime();

          if (diffInMilliseconds < 60 * 1000) {
            setIsFiniteAppAlive(true);
          } else {
            setIsFiniteAppAlive(false);
            setLastAppActiveText(timeAgo(firstDeviceItem.alive_at));
          }
        }
      } catch (e) {
        console.error("Error: ", e);
        setIsFiniteAppAlive(false);
      } finally {
        setFiniteAppStatusLoading(false);
      }
    }

    getDeviceAliveStatus();
    timer = setInterval(getDeviceAliveStatus, 5000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Layout style={{ minHeight: "100vh" }} key={pathname}>
      <Sider
        collapsible
        theme="light"
        collapsed={collapsed}
        onCollapse={() => {
          setCollapsed(!collapsed);
        }}
        width="280"
        style={{
          overflow: "auto",
          height: "95vh",
          position: "fixed",
          left: 0,
        }}
      >
        <div className="mt-[28px]">
          {collapsed ? (
            <div className="whitespace-normal">
              <FiniteTimeFavicon className="w-[36%] mx-[20px]" />
            </div>
          ) : (
            <FiniteTimeLogo className="w-[70%] h-[50%]" />
          )}
        </div>
        <div className="ml-[20px] mb-[20px] flex items-center gap-2">
          <div
            className={`w-3 h-3 rounded-full`}
            style={{
              backgroundColor: finiteAppStatusLoading
                ? "#9ab6c9"
                : isFiniteAppAlive
                ? "rgb(74 222 128)"
                : "rgb(248 113 113)",
            }}
          ></div>
          {finiteAppStatusLoading
            ? "Fetching status..."
            : isFiniteAppAlive
            ? "App is Live"
            : `App was active ${lastAppActiveText}`}
        </div>
        <Menu
          defaultSelectedKeys={[`${pathname}`]}
          mode="inline"
          className="side-panel-menu"
        >
          {!collapsed && (
            <div className="flex-col">
              <TodoButton />
              <Menu.Divider style={{ margin: "40px 0" }} />
            </div>
          )}
          {sidebarItems.map((panel) => (
            <Menu.Item key={panel.key} icon={<div>{panel.icon}</div>}>
              <Link to={panel.key} className="mb-1 font-semibold pr-3">
                {panel.label}
              </Link>
            </Menu.Item>
          ))}
          {!collapsed && (
            <>
              <Menu.Divider style={{ margin: 0, marginTop: "40px" }} />
              <Timer />
            </>
          )}
        </Menu>
      </Sider>
      <Layout>
        <Content
          width="100%"
          style={{
            overflow: "auto",
            marginLeft: !collapsed ? "308px" : "108px",
            marginRight: "2%",
            transition: "all 0.1s ease-in-out",
            position: "relative",
            marginBottom: "24px",
          }}
        >
          <div className="w-full flex flex-col">
            <Header />
            <Suspense
              fallback={
                <Row className="h-screen justify-center items-center w-full">
                  <Spin />
                </Row>
              }
            >
              <Outlet />
            </Suspense>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Home;
